.backdrop {
    /* should take the full page width and height */
    position: fixed;
    width: 100%; 
    height: 100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.2);
    z-index: 100;
}
@media (min-width:576px){
    .backdrop {
        display: none;
    }
}