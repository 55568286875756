.button-grouping {
    display: inline;
}

.button-grouping > span {
    margin: 10px;
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}